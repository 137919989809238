myApp.factory('tokenService',  function($http){
	var promise;
	var myService = {
		get : function(){
			if(!promise){
				promise = $http.get(baseUrl + 'auth/token/').then(function(response){
					console.log(response);
					return response;
				});	
			}
			return promise;
		},	
	};
	return myService;
});