angular.module('authService',[])
    .factory('authUser',function($auth){
        var login = function(loginForm){
            $auth.login(loginForm).then(        
                    function(response){
                        return response;
                    },
                    function(error){

                        return error;
                    }
            );
        }
        return {
            loginApi : function (loginForm){
                login(loginForm);
            }
        }
});